<template>
    <div class="modal profile_modal schedule" v-if="modelValue && !isAllSchedule" :class="{active : modelValue}">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1 class="m-0 pb-4">Scheduled SMS</h1>
                <button class="close_btn" @click="closeModel"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal_body">
                <div class="result_wpr new">
                    <div class="actions">
                        <ul class="left">
                            <li class="edit">
                                <label for="check" class="checkbox">
                                    <input type="checkbox" id="check" v-model="selectAll" :true-value="1" :false-value="0" @change="toggleSelectAll"  hidden>
                                    <span><i class="fas fa-check"></i></span>
                                </label>
                            </li>
                            <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePageFilter">
                                {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                                <page-filter v-model="params.per_page" :type="2" :is-dropdown="true" ref="per-page-filter" />
                            </li>
                        </ul>
                        <ul class="right">
                            <li class="search_area">
                                <input type="text" @input="isTyping = true" v-model.trim="params.search"  placeholder="Search"/>
                                <div class="search_btn">
                                    <i class="fas fa-search"></i>
                                </div>
                            </li>
                            <li class="delete" @click="handleDeleteSms()"><i class="fas fa-trash-alt"></i></li>
                            <!-- <li class="list_info">{{ scheduledSms.from ? scheduledSms.from : 0 }} - {{ scheduledSms.to ? scheduledSms.to : 0 }} of <span>{{ scheduledSms.total }}</span></li> -->
                        </ul>
                    </div>
                    <div v-if="scheduleLoader"><line-loader /></div>
                    <sms-component v-if="selectedSchedule == 'Scheduled SMS'" :scheduled-sms="scheduledSms" v-model="params.schedule_ids"/>
                </div>
                <div class="table_footer">
                    <ul>
                        <li>{{ scheduledSms.from ? scheduledSms.from : 0 }} - {{ scheduledSms.to ? scheduledSms.to : 0 }} of <span>{{ scheduledSms.total }}</span></li>
                    </ul>
                </div>
                <div class="pagination pb-4 mt-4" v-if="scheduledSms.total">
                    <pagination :range-size="0" v-model="params.page" :pages="scheduledSms.last_page" @update:modelValue="handlePaginationSms" />
                </div>
            </div>
            <div class="modal_footer">
                <button type="button" class="btn cancel_btn" @click="closeModel()">Cancel</button>
            </div>
        </div>
    </div>
    <div class="preview_area show_sidebar" v-if="modelValue && isAllSchedule" :class="{active : modelValue}">
        <div class="global_setting">
            <button class="close_btn modal-window-close-button" @click="closeModel"><i class="fas fa-long-arrow-alt-left"></i></button>
            <div class="tabs_content">
                <div class="content_area">
                    <div class="section_content w-100">
                        <div class="section_header">
                            <h2>Bulk Action - Schedules</h2>
                        </div>
                        <div class="result_wpr new">
                            <div class="actions mt-2">
                                <ul>
                                    <li>
                                        <label for="check" class="checkbox">
                                            <input type="checkbox" id="check" v-model="selectAll" :true-value="1" :false-value="0" @change="toggleSelectAll"  hidden>
                                            <span><i class="fas fa-check"></i></span>
                                            <h5>{{ selectAll ? `${selectAll} Selected` : `Select All ${params.per_page}`}}</h5>
                                        </label>
                                    </li>
                                    <li class="optionDrops contacts-tabs" @click="toggleSchedule()" v-click-outside="closeScheduleFilter">
                                        {{ selectedSchedule }}<i class="fas fa-angle-down"></i>
                                        <div class="dropdown_wpr" :class="scheduleList ? 'active' : ''">
                                            <ul class="scroll_bar">
                                                <li v-for="(schedule, s) in availableSchedules" :key="s" @click="selectedSchedule = schedule">{{ schedule }}</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li class="search_area ml-auto" :class="{'active': searchField}">
                                        <input type="text" @input="isTyping = true" v-model.trim="params.search"  placeholder="Search"/>
                                        <button class="search_btn" @click="searchField = !searchField;">
                                            <i class="fas fa-search"></i>
                                        </button>
                                    </li>
                                    <li class="edit" @click="handleDeleteSms()" v-if="selectedSchedule == 'Scheduled SMS'"><i class="fas fa-trash-alt danger"></i></li>
                                    <li class="edit" @click="handleDeleteSchedule()" v-else><i class="fas fa-trash-alt danger"></i></li>
                                    <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePageFilter">
                                        Show {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                                        <page-filter v-model="params.per_page" :type="2" :is-dropdown="true" ref="per-page-filter" />
                                    </li>
                                </ul>
                            </div>
                            <div v-if="scheduleLoader"><line-loader /></div>
                            <div class="scroll_table">
                                <sms-component v-if="selectedSchedule == 'Scheduled SMS'" :scheduled-sms="scheduledSms" v-model="params.schedule_ids" :update-select-all="updateSelectAll" />
                                <email-component-local v-if="selectedSchedule == 'Scheduled Email'" :schedules="schedules" v-model="params.schedule_ids" :update-select-all="updateSelectAll" />
                                <gender-component v-if="selectedSchedule == 'Change Gender'" :schedules="schedules" v-model="params.schedule_ids" :update-select-all="updateSelectAll" />
                                <tab-component v-if="selectedSchedule == 'Change Tab'" :schedules="schedules" v-model="params.schedule_ids" :update-select-all="updateSelectAll" />
                                <tag-component v-if="selectedSchedule == 'Scheduled Tag'" :schedules="schedules" v-model="params.schedule_ids" :update-select-all="updateSelectAll" />
                                <pipeline-component v-if="selectedSchedule == 'Scheduled Pipeline'" :schedules="schedules" v-model="params.schedule_ids" :update-select-all="updateSelectAll" />
                                <sequence-component v-if="selectedSchedule == 'Scheduled Sequence'" :schedules="schedules" v-model="params.schedule_ids" :update-select-all="updateSelectAll" />
                                <journey-component v-if="selectedSchedule == 'Scheduled Journey'" :schedules="schedules" v-model="params.schedule_ids" :update-select-all="updateSelectAll" />
                                <playbook-component v-if="selectedSchedule == 'Scheduled Playbook'" :schedules="schedules" v-model="params.schedule_ids" :update-select-all="updateSelectAll" />
                                <template-component v-if="selectedSchedule == 'Scheduled Template'" :schedules="schedules" v-model="params.schedule_ids" :update-select-all="updateSelectAll" />
                                <form-component v-if="selectedSchedule == 'Scheduled Form'" :schedules="schedules" v-model="params.schedule_ids" :update-select-all="updateSelectAll" />
                                <page-component v-if="selectedSchedule == 'Scheduled Page'" :schedules="schedules" v-model="params.schedule_ids" :update-select-all="updateSelectAll" />
                                <progress-tracking v-if="selectedSchedule == 'Progress Tracking'" :schedules="schedules" v-model="params.schedule_ids" :update-select-all="updateSelectAll" />
                                <habit-tracking v-if="selectedSchedule == 'Habit Tracking'" :schedules="schedules" v-model="params.schedule_ids" :update-select-all="updateSelectAll" />
                                <workout-component v-if="selectedSchedule == 'Scheduled Workout'" :schedules="schedules" v-model="params.schedule_ids" :update-select-all="updateSelectAll" />
                                <workout-plan-component v-if="selectedSchedule == 'Scheduled Workout Plan'" :schedules="schedules" v-model="params.schedule_ids" :update-select-all="updateSelectAll" />
                            </div>
                        </div>
                        <div class="table_footer">
                            <ul>
                                <li v-if="selectedSchedule == 'Scheduled SMS'">{{ scheduledSms.from ? scheduledSms.from : 0 }} - {{ scheduledSms.to ? scheduledSms.to : 0 }} of {{ scheduledSms.total }}</li>
                                <li v-else>{{ schedules.from ? schedules.from : 0 }} - {{ schedules.to ? schedules.to : 0 }} of {{ schedules.total }}</li>
                            </ul>
                        </div>
                        <div class="pagination" v-if="scheduledSms.total && selectedSchedule == 'Scheduled SMS'">
                            <pagination :range-size="0" v-model="params.page" :pages="scheduledSms.last_page" @update:modelValue="handlePaginationSms" />
                        </div>
                        <div class="pagination" v-if="schedules.total && selectedSchedule !== 'Scheduled SMS'">
                            <pagination :range-size="0" v-model="params.page" :pages="schedules.last_page" @update:modelValue="handlePagination" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    const SmsComponent = defineAsyncComponent(() => import('@/pages/admin-setting/components/report-schedule/schedule/Sms'))
    const EmailComponentLocal = defineAsyncComponent(() => import('@/pages/admin-setting/components/report-schedule/schedule/Email'))
    const GenderComponent = defineAsyncComponent(() => import('@/pages/admin-setting/components/report-schedule/schedule/Gender'))
    const TabComponent = defineAsyncComponent(() => import('@/pages/admin-setting/components/report-schedule/schedule/Tab'))
    const TagComponent = defineAsyncComponent(() => import('@/pages/admin-setting/components/report-schedule/schedule/Tag'))
    const PipelineComponent = defineAsyncComponent(() => import('@/pages/admin-setting/components/report-schedule/schedule/Pipeline'))
    const SequenceComponent = defineAsyncComponent(() => import('@/pages/admin-setting/components/report-schedule/schedule/Sequence'))
    const JourneyComponent = defineAsyncComponent(() => import('@/pages/admin-setting/components/report-schedule/schedule/Journey'))
    const PlaybookComponent = defineAsyncComponent(() => import('@/pages/admin-setting/components/report-schedule/schedule/Playbook'))
    const TemplateComponent = defineAsyncComponent(() => import('@/pages/admin-setting/components/report-schedule/schedule/Template'))
    const FormComponent = defineAsyncComponent(() => import('@/pages/admin-setting/components/report-schedule/schedule/Form'))
    const PageComponent = defineAsyncComponent(() => import('@/pages/admin-setting/components/report-schedule/schedule/Page'))
    const ProgressTracking = defineAsyncComponent(() => import('@/pages/admin-setting/components/report-schedule/schedule/ProgressTracking'))
    const HabitTracking = defineAsyncComponent(() => import('@/pages/admin-setting/components/report-schedule/schedule/HabitTracking'))
    const WorkoutComponent = defineAsyncComponent(() => import('@/pages/admin-setting/components/report-schedule/schedule/Workout'))
    const WorkoutPlanComponent = defineAsyncComponent(() => import('@/pages/admin-setting/components/report-schedule/schedule/WorkoutPlan'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import moment from 'moment'

    export default {
        name: 'Schedule',

        data () {
            return {
                scheduleList: false,
                searchField: false,
                params: {
                    page: 1,
                    per_page: 5,
                    search: '',
                    order: 'desc',
                    orderBy: 'id',
                    schedule_ids: [],
                },
                availableSchedules: [
                    'Scheduled SMS',
                    'Scheduled Email',
                    'Change Gender',
                    'Change Tab',
                    'Scheduled Tag',
                    'Scheduled Pipeline',
                    'Scheduled Sequence',
                    'Scheduled Journey',
                    'Scheduled Playbook',
                    'Scheduled Template',
                    'Scheduled Form',
                    'Scheduled Page',
                    'Progress Tracking',
                    'Habit Tracking',
                    'Scheduled Workout',
                    'Scheduled Workout Plan',
                ],
                moment,
                selectedSchedule: 'Scheduled SMS',
                selectAll: 0,
                isTyping: true,
            }
        },

        props: {
            modelValue: Boolean,
            isAllSchedule: {
                type: Boolean,
                default: true,
            }
        },

        emit: ['update:modelValue'],

        components:{
            SmsComponent,
            EmailComponentLocal,
            GenderComponent,
            TabComponent,
            TagComponent,
            PipelineComponent,
            SequenceComponent,
            JourneyComponent,
            PlaybookComponent,
            TemplateComponent,
            FormComponent,
            PageComponent,
            ProgressTracking,
            HabitTracking,
            WorkoutComponent,
            WorkoutPlanComponent,
        },

        watch : {
            selectedSchedule (type) {
                const vm  = this;

                vm.params = {
                    page: 1,
                    per_page: 10,
                    search: '',
                    order: 'desc',
                    orderBy: 'id',
                    schedule_ids: [],
                };

                if (type == 'Scheduled SMS') {
                    vm.getScheduleSms(vm.params);
                } else if (type == 'Scheduled Email') {
                    vm.params.action = ['/contacts/send-email']
                } else if (type == 'Change Gender') {
                    vm.params.action = ['/contacts/update-contacts-gender']
                } else if (type == 'Change Tab') {
                    vm.params.action = ['/contacts/update-contacts-status']
                } else if (type == 'Scheduled Tag') {
                    vm.params.action = ['/contacts/add-tag','/contacts/remove-tag']
                } else if (type == 'Scheduled Pipeline') {
                    vm.params.action = [' /pipeline/contacts-add','/pipeline/contacts-remove']
                } else if (type == 'Scheduled Sequence') {
                    vm.params.action = ['/contacts/add-sequence','/contacts/stop-sequence']
                } else if (type == 'Scheduled Journey') {
                    vm.params.action = ['/contacts/start-journey','/contacts/terminate-journey']
                } else if (type == 'Scheduled Playbook') {
                    vm.params.action = ['/contacts/send-playbook','/contacts/restrict-playbook']
                } else if (type == 'Scheduled Template') {
                    vm.params.action = ['/contacts/send-template']
                } else if (type == 'Scheduled Form') {
                    vm.params.action = ['/contacts/send-form','/contacts/restrict-form']
                } else if (type == 'Scheduled Page') {
                    vm.params.action = ['/contacts/send-page','/contacts/restrict-page']
                } else if (type == 'Progress Tracking') {
                    vm.params.action = ['/progress-tracking/assign-to-contacts','/progress-tracking/unassign-from-contacts']
                } else if (type == 'Habit Tracking') {
                    vm.params.action = ['/habit-tracking/assign-to-contacts','/habit-tracking/unassign-from-contacts']
                } else if (type == 'Scheduled Workout') {
                    vm.params.action = ['/workout/assign-to-contacts','/workout/unassign-from-contacts']
                } else if (type == 'Scheduled Workout Plan') {
                    vm.params.action = ['/workout-plan/assign-to-contacts','/workout-plan/unassign-from-contacts']
                }

                if (type !== 'Scheduled SMS') {
                    vm.getSchedule(vm.params);
                }
            },

            'params.per_page' () {
                const vm = this;
                vm.params.page = 1;

                vm.selectedSchedule == 'Scheduled SMS' ? vm.getScheduleSms(vm.params) : vm.getSchedule(vm.params);
            },

            'params.search' () {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2 || vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.selectedSchedule == 'Scheduled SMS' ? vm.getScheduleSms(vm.params) : vm.getSchedule(vm.params);
                        }
                    }
                }
            },

            modelValue (value) {
                if (value) {
                    const vm  = this;

                    setTimeout(function () {
                        vm.getScheduleSms (vm.params);
                    }, 1000);
                }
            },
        },

        computed: mapState({
            scheduledSms: state => state.scheduleModule.scheduledSMS,
            schedules: state => state.scheduleModule.schedules,
            scheduleLoader: state => state.scheduleModule.scheduleLoader,
        }),

        methods:{
            ...mapActions({
                getScheduleSms: 'scheduleModule/getScheduleSMS',
                deleteScheduledSMS: 'scheduleModule/deleteScheduledSMS',
                getSchedule: 'scheduleModule/getSchedule',
                deleteSchedule: 'scheduleModule/deleteSchedule',
            }),

            closeModel () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleDeleteSms () {
                const vm = this;

                if (vm.params.schedule_ids.length !== 0) {
                    const option = Helper.swalConfirmOptions('Are You Sure?', "You will not be able to recover these scheduled sms.", 'Yes')
                    option.preConfirm = function() {
                                            const params = Object.assign({}, vm.params);

                                            return vm.deleteScheduledSMS(params);
                                        };

                    Swal.fire(option);
                } else {
                    const option = Helper.swalWarningOptions('Alert!', 'Please select at least one in the scheduled sms!', 'Okay')

                    Swal.fire(option);
                }
            },

            handleDeleteSchedule () {
                const vm = this;

                if (vm.params.schedule_ids.length !== 0) {
                    const option = Helper.swalConfirmOptions('Are You Sure?', "You will not be able to recover these scheduled sms.", 'Yes')
                    const params = Object.assign({}, vm.params);
                    option.preConfirm = function() {
                                            const params = Object.assign({}, vm.params);

                                            return vm.deleteSchedule(params);
                                        };

                    Swal.fire(option);
                } else {
                    const option = Helper.swalWarningOptions('Alert!', 'Please select at least one in the scheduled sms!', 'Okay')

                    Swal.fire(option);
                }
            },

            handlePaginationSms (page) {
                const vm = this;

                vm.params.page = page;
                vm.getScheduleSms(vm.params);
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getSchedules(vm.params);
            },

            togglePerPageFilter (value = undefined) {
                const vm = this;

                const filter = vm.$refs['per-page-filter'];

                if (filter) {
                    if (value == 0) {
                        filter.dropdown = false;
                    } else {
                        filter.dropdown = !filter.dropdown;
                        vm.scheduleList = false;
                    }
                }
            },

            toggleSchedule () {
                const vm = this;

                vm.scheduleList = !vm.scheduleList;
                vm.togglePerPageFilter(0);
            },

            toggleSelectAll () {
                const vm = this;

                if (vm.selectAll == 1) {
                    if (vm.selectedSchedule == 'Scheduled SMS') {
                        vm.scheduledSms.data.forEach((result) => {
                            vm.params.schedule_ids.push(result.id);
                        });
                    } else {
                        vm.schedules.data.forEach((result) => {
                            vm.params.schedule_ids.push(result.id);
                        });
                    }
                } else {
                    vm.params.schedule_ids = [];
                }
            },

            closePageFilter () {
                const vm = this;
                const filter = vm.$refs['per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            closeScheduleFilter () {
                const vm = this;

                vm.scheduleList = false;
            },

            updateSelectAll (val) {
                const vm = this;

                vm.selectAll = val;
            }
        }
    }
</script>

<style scoped>
    .global_setting .tabs_content::-webkit-scrollbar {
        width: 4px;
        display: block;
    }

    .global_setting .tabs_content:hover::-webkit-scrollbar-thumb {
        background-color: #b8b8b8;
        border-radius: 2px;
    }

    .modal.schedule .modal_container {
        height: 640px;
    }

    .schedule .modal_header {
        padding: 20px 30px 0;
    }

    .schedule .modal_body {
        padding: 15px 30px;
        flex-direction: column;
    }

    .modal_footer {
        border-radius: 0 0 12px 12px;
    }

    .result_wpr table{
        min-width: 1000px !important;
    }

    .modal.profile_modal .result_wpr .actions{
        min-width: auto !important;
    }
    .result_wpr.new .actions > ul li.search_area{
        padding: 0 31px 0 0;
        border-radius: 17px;
        background: #fff;
        position: relative;
        margin-left: 20px;
    }
    .result_wpr .actions > ul li.delete{
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        border-radius: 50%;
        padding: 0;
    }
    .result_wpr .actions > ul li.delete i{
        color: #eb1414;
        margin-left: 0;
    }
    .result_wpr .actions > ul li.edit i{
        font-size: 12px;
    }
    @media(max-width: 1199px){
        .global_setting .tabs_content .content_area{
            padding: 30px 10px 0 10px;
        }
    }
    @media(max-width: 767px){
        .result_wpr.new .actions > ul{
            flex-wrap: nowrap !important;
        }
        .result_wpr.new .actions > ul li.search_area{
            width: 100%;
            order: -1;
        }
        .result_wpr.new .actions > ul li.sort_list{
            margin-left: auto;
        }
        .result_wpr.new .actions > ul li.search_area input[type=text]{
            width: 200px;
            height: 32px;
        }
    }
    @media(max-width: 450px){
        .result_wpr.new .actions > ul li:first-child{
            width: 50%;
        }
        .result_wpr.new .actions > ul li.contacts-tabs{
            order: 1;
        }
        .result_wpr.new .actions > ul li.edit{
            order: 1;
            margin-left: auto;
        }
    }
</style>
